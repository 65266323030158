export default function useRedirectToHome() {
  const route = useRoute()

  const redirectToHome = () => {
    // Prevent loops
    if (route.path !== '/') {
      navigateTo('/')
    }
  }

  return { redirectToHome }
}
